<template>
  <app-modal
    modal-class="modal-confirm slide-up"
    size="lg"
    v-on="$listeners"
    v-bind="$attrs"
    ref="modal"
    :cancelText="cancelText"
    :confirmTextText="confirmTextText"
  >
    <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>
  </app-modal>
</template>

<script>
export default {
  name: "KyyAppModalSendingInvoice",

  props: {
    confirmClass: String,
    cancelClass: String,
    cancelText: String,
    confirmTextText: String,
  },

  methods: {
    hide() {
      this.$refs.modal.hide();
    },

    show() {
      this.$refs.modal.show();
    },
  },
};
</script>
